import styles from './styles/AdPlaceholder.module.scss';
import { HTMLAttributes } from 'react';
import cn from 'classnames';

export type AdPlaceholderProps = Omit<HTMLAttributes<HTMLDivElement>, 'placeholder'> & { placeholder?: boolean };

export default function AdPlaceholder({ className, placeholder, children, ...props }: AdPlaceholderProps) {
  return (
    <div className={cn({ [styles.Placeholder]: placeholder }, className)} {...props}>
      {placeholder && children}
    </div>
  );
}
